/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Element } from 'react-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Lazyload from 'react-lazyload';
import { CSSTransition } from 'react-transition-group';

import {
  BOOKING_DEPOSIT,
  NEW_ADVENTURE_DEPOSIT,
  NEW_ADVENTURE_TOUR_SLUGS,
  PAGES,
  TOUR_PAGE_NAV,
} from 'config';
import { Layout } from 'containers';
import { Breadcrumbs, Gallery, AlertBanner, FooterBanner } from 'components';
import { GET_TOUR_DEPARTURES } from 'actions/tours';
import {
  FORMAT_GRAPH_DATA,
  getImageUrl,
  isMobileScreen,
  mapStateToProps,
} from 'utilities';
import {
  FaqsList,
  TourList,
  InstagramList,
  GuestReviews,
} from 'layouts';
import {
  TourBanner,
  PageNav,
  Summary,
  Itinerary,
  WhatsIncluded,
  AddonsUpgrades,
  Dates,
  SideBox,
} from './components';

class TourPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      departures: [],
      showLess: true,
    };

    this.toggleReadMore = this.toggleReadMore.bind(this);
  }

  componentDidMount() {
    const { pageContext } = this.props;
    if (typeof fbq !== 'undefined') {
      this.trackFacebookPageViewEvent();
    }
    (async () => {
      // Get departures fresh!
      const DEPARTURES = await GET_TOUR_DEPARTURES(pageContext.api_id);
      if (DEPARTURES && DEPARTURES.success) {
        this.setState({
          departures: DEPARTURES.data,
        });
      }
    })();
  }

  /**
   * Get the gallery slides
   * @returns array
   */
  getGallerySlides() {
    const { pageContext, data } = this.props;

    const slideIDs = [
      pageContext.sliding_first_picture_id,
      pageContext.sliding_second_picture_id,
      pageContext.sliding_third_picture_id,
      pageContext.sliding_fourth_picture_id,
      pageContext.sliding_fifth_picture_id,
      pageContext.sliding_sixth_picture_id,
      pageContext.sliding_seventh_picture_id,
      pageContext.sliding_eighth_picture_id,
      pageContext.sliding_ninth_picture_id,
      pageContext.sliding_tenth_picture_id,
    ];

    const slides = slideIDs
      .filter(id => id)
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images: data.allTourImages.edges,
          width: 773,
          height: 475,
        }),
        thumbnailUrl: getImageUrl({
          id,
          images: data.allTourImages.edges,
          width: 75,
        }),
      }));

    // If a video link exists, add it as the first array item
    if (pageContext.sliding_video_link) {
      slides.unshift({
        id: pageContext.sliding_video_link,
        videoUrl: pageContext.sliding_video_link,
      });
    }

    return slides;
  }

  toggleReadMore() {
    this.setState(oldState => ({ showLess: !oldState.showLess }));
  }

  trackFacebookPageViewEvent() {
    const { pageContext } = this.props;
    fbq('trackSingle', '2027634157481410', 'ViewContent', {
      content_type: 'destination',
      content_ids: pageContext.id,
      city: pageContext.fb_pixel_city,
      region: pageContext.fb_pixel_region,
      country: 'New Zealand',
    });
  }

  /**
   * Render
   */
  render() {
    const { pageContext, data, messages } = this.props;
    const { departures, showLess } = this.state;

    const hasMessage = !!(messages && Array.isArray(messages.messages) && messages.messages.length);

    const category = {
      name: pageContext.parent_name,
      background_color: pageContext.background_color,
      category_icon: pageContext.parent_category_icon && pageContext.parent_category_icon.svg_text,
    };

    const breadcrumbs = [
      {
        name: PAGES.TOURS.TITLE,
        absolute_slug: PAGES.TOURS.PATH,
      },
      {
        name: pageContext.parent_name,
        absolute_slug: pageContext.parent_absolute_slug,
      },
      {
        name: pageContext.name,
        absolute_slug: pageContext.absolute_slug,
      },
    ];

    const promoBanner = FORMAT_GRAPH_DATA(data.allPromoBanners).filter(item => item.tour_ids.includes(pageContext.api_id));

    const sideBox = modifier => (
      <SideBox
        tourId={pageContext.api_id}
        title={pageContext.name}
        tourSlug={pageContext.slug}
        duration={pageContext.number_of_days}
        inactiveDays={pageContext.b2r_tour_inactive}
        isBornToRoam={pageContext.is_born_to_roam_tour}
        price={pageContext.starting_price}
        deposit={NEW_ADVENTURE_TOUR_SLUGS.includes(pageContext.slug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT}
        showDownload={!!pageContext.itinerary_form_code}
        promoBanner={promoBanner}
        modifier={modifier}
        hasMessage={hasMessage}
      />
    );

    // exclude from page nav if no content
    const PAGE_NAV_EXCLUDE = [];
    if (!pageContext.faqs || !pageContext.faqs.length) {
      PAGE_NAV_EXCLUDE.push(TOUR_PAGE_NAV.FAQS.NAME);
    }
    if (pageContext.is_born_to_roam_tour) {
      PAGE_NAV_EXCLUDE.push(TOUR_PAGE_NAV.DATES.NAME);
    }

    const tourList = FORMAT_GRAPH_DATA(data.allTourPages)
      .filter(item => item.api_id !== pageContext.api_id && item.category_ids.includes(pageContext.parent_id));

    const addons = FORMAT_GRAPH_DATA(data.allActivityPages).filter(item => item.tour_ids.includes(pageContext.api_id));
    const upgrades = FORMAT_GRAPH_DATA(data.allActivityPages).filter(item => pageContext.featured_addon_ids.includes(item.api_id));
    const essentials = FORMAT_GRAPH_DATA(data.allActivityPages).filter(item => pageContext.optional_addon_ids.includes(item.api_id));
    const alertMessage = [{ type: 'success', content: '<a href="/discounts">SPRING SALE Save 10% on spring dates on select Born to Roam tours.</a>' }];
    const productSchema = [
      {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: pageContext.name,
        image: pageContext.schema_image_url,
        description: pageContext.meta_description,
        sku: pageContext.slug,
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: pageContext.tour_reviews[0],
            reviewCount: pageContext.tour_reviews[1]
        },
        offers: {
            '@type': 'Offer',
            priceCurrency: 'NZD',
            price: pageContext.starting_price,
            availability: 'http://schema.org/InStock'
        }
      }
    ];

    return (
      <Layout page="tours">
        <Helmet
          title={pageContext.page_title}
          meta={[
            { name: 'description', content: pageContext.meta_description },
            { name: 'og:title', content: pageContext.og_title },
            { name: 'og:description', content: pageContext.og_description },
            { name: 'og:image:secure', content: pageContext.schema_image_url },
            { name: 'og:image', content: pageContext.schema_image_url },
          ]}
          link={[{ rel: 'canonical', href: `${pageContext.absolute_slug}/` }]}
        >
          <script type="application/ld+json">
            {JSON.stringify(productSchema)}
          </script>
        </Helmet>

        <TourBanner title={pageContext.name} tourId={pageContext.id} />

        <PageNav
          title={pageContext.name}
          exclude={PAGE_NAV_EXCLUDE}
          hasMessage={hasMessage}
        />

        <div className="l-tour-page">
          <div className="l-container">
            <Breadcrumbs list={breadcrumbs} />

            <div className="l-tour-page__grid">
              <div>
                <Gallery
                  slides={this.getGallerySlides()}
                  category={category}
                  showThumbnails
                />

                {typeof window !== 'undefined' && isMobileScreen(window) && sideBox()}

                <Element
                  name={TOUR_PAGE_NAV.SUMMARY.NAME}
                  className="l-tour-summary l-tour-page__section"
                >
                  <h1 className="c-heading c-heading--h3">
                    {pageContext.h1_header}
                  </h1>
                  <Summary description={pageContext.description_markdown} showLess={showLess} />

                  <button className="u-reset-btn l-tour-summary__read-more-btn" type="button" onClick={this.toggleReadMore}>
                    {showLess ? 'Read more' : 'Read less'}
                  </button>
                  {console.log(pageContext)}
                  {!!pageContext.highlights.length && (
                    <div className="l-tour-summary__highlights">
                      <h4 className="c-heading c-heading--h4">Tour highlights</h4>
                      <div className="l-tour-summary__highlights-inner">
                        <div className="l-tour-summary__highlights-grid">
                          {pageContext.highlights.map(item => (
                            <div
                              key={item.id}
                              className="l-tour-summary__highlights-box"
                            >
                              <div className="l-tour-summary__highlights-box-image">
                                <LazyLoadImage
                                  src={`${item.image_url}?w=250&fit=crop&auto=compress,format`}
                                  effect="blur"
                                  wrapperClassName="lazy-img-wrapper"
                                  alt={item.title}
                                />
                              </div>
                              <h5 className="c-heading c-heading--h5">
                                {item.title}
                              </h5>
                              <p>
                                {item.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </Element>

                <Itinerary
                  pageContext={pageContext}
                  images={data.allTourImages.edges}
                />

                <WhatsIncluded markdown={pageContext.what_is_included} />

                <AddonsUpgrades
                  addons={addons}
                  upgrades={upgrades}
                  essentials={essentials}
                  images={data.allActivityImages.edges}
                />
              </div>

              {sideBox(promoBanner.length === 0 ? 'c-side-box--fixed-no-promo' : 'c-side-box--fixed')}
            </div>

            <GuestReviews tourId={pageContext.id} />

            {!pageContext.is_born_to_roam_tour && (
              <Dates
                list={departures}
                tourName={pageContext.name}
                tourSlug={pageContext.slug}
                tourStartLocation={pageContext.start_location}
                tourEndLocation={pageContext.end_location}
                tourDuration={pageContext.number_of_days}
                hasReverse={pageContext.has_reverse}
                pageContext={pageContext}
              />
            )}
          </div>
        </div>

        <Element name={TOUR_PAGE_NAV.FAQS.NAME}>
          <FaqsList
            title={`FAQ - ${pageContext.name}`}
            list={pageContext.faqs}
          />
        </Element>

        <TourList
          heading={<h2 className="c-heading c-heading--h2">Similar Tours</h2>}
          list={tourList}
          limit={3}
          hideFilters
        />

        <InstagramList
          title={pageContext.parent_crowdriff_header}
          subTitle="Want to be featured here? Simply tag your Insta pics with #hakatours or upload your pictures <a href='https://upload.crowdriff.com/haka-tours-guest-images' target='_blank'>here</a>."
          hash={pageContext.crowdriff_hash || pageContext.parent_crowdriff_code}
        />

        {pageContext.parent_absolute_slug === '/new-zealand-tours' && (
          <Lazyload
            height={400}
            offset={100}
            once
            resize
          >
            <CSSTransition
              key="1"
              transitionName="fade"
              timeout={100}
              transitionAppear
              transitionAppearTimeout={500}
              transitionEnter={false}
              transitionLeave={false}
            >
              <FooterBanner />
            </CSSTransition>
          </Lazyload>
        )}
      </Layout>
    );
  }
}

TourPage.propTypes = {
  data: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
                hidden_category: { eq: false },
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          number_of_days
          starting_price
          has_reverse
          short_description_markdown
          parent_name
          parent_id
          tour_reviews
          absolute_slug
          og_title
          og_description
          destination_ids
          category_ids
          picture_id
          schema_image_url
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          background_color
          start_location
          end_location
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }

    allActivityPages(
      filter: {
                is_included_activity: { eq: false },
                guide_app_only: { ne: true },
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          tour_ids
          destination_name
          is_activity_supplier
          is_accommodation_supplier
          description_markdown
          sliding_first_picture_id,
          sliding_second_picture_id,
          sliding_third_picture_id,
          sliding_fourth_picture_id,
          sliding_fifth_picture_id,
          options {
            id
            name
            hidden
            description
            price
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allTourImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }

    allPromoBanners {
      edges {
        node {
          id
          title
          description_markdown
          link
          tour_ids
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }

    allReviews(
      sort: {
        fields: date_published,
        order: DESC
      }
      filter: {
        date_published: {
          ne: null
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          tour_id
          author
          country
          text
          image_url
          date_published
        }
      }
    }
  }
`;

export default connect(mapStateToProps)(TourPage);
