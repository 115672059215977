import React from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

const showdown = require('showdown');

const converter = new showdown.Converter();

const Summary = ({ description, showLess }) => {
  let descriptionHtml = <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(description) }} />;
  if (showLess) {
    descriptionHtml = <HTMLEllipsis unsafeHTML={converter.makeHtml(description)} maxLine="7" ellipsis="..." basedOn="letters" />;
  }
  return descriptionHtml;
};

Summary.defaultProps = {
  description: null,
  showLess: true,
};

Summary.propTypes = {
  description: PropTypes.string,
  showLess: PropTypes.any,
};


export default Summary;
