import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';
import moment from 'moment';
import { Link } from 'gatsby';
import ReactTooltip from 'react-tooltip';

import {
  TOUR_PAGE_NAV, PAGES, BOOKING_DEPOSIT, NEW_ADVENTURE_DEPOSIT, NEW_ADVENTURE_TOUR_SLUGS, PARTIAL_PAYMENT_DEADLINE, SCROLL_CONFIG, HEADER_HEIGHT_MOBILE, HEADER_HEIGHT, TOUR_PAGE_NAV_HEIGHT,
} from 'config';
import { numberToCurrency, daysFromNow, isMobileScreen } from 'utilities';
import { FormSelect, withFormGroup } from 'components';


const ROUTE_OPTIONS = {
  STANDARD: {
    title: 'Standard route',
    value: 'STANDARD',
  },
  REVERSE: {
    title: 'Reverse route',
    value: 'REVERSE',
  },
};

const SINGLE_ROUTE_OPTIONS = {
  STANDARD: {
    title: 'Standard route',
    value: 'STANDARD',
  },
};

const FIELDS = {
  DEPARTURES: {
    ID: 'TourDepartures',
    OPTIONS: [
      {
        id: 1,
        name: 'Departing in next 30 days',
        value: 'next-30-days',
      },
      {
        id: 2,
        name: 'Departing in next 90 days',
        value: 'next-90-days',
      },
      {
        id: 3,
        name: 'Departing after 90 days',
        value: 'after-90-days',
      },
    ],
  },
  ROUTES: {
    ID: 'TourRoutes',
    OPTIONS: Object.values(ROUTE_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
    })),
  },
  SINGLE_ROUTES: {
    ID: 'TourRoutes',
    OPTIONS: Object.values(SINGLE_ROUTE_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
    })),
  },
};

const Select = withFormGroup(FormSelect);

const SHOW_INITIAL_LENGTH = 7;

class Dates extends PureComponent {
  /**
   * Initial state values
   */
  static values() {
    const { DEPARTURES, ROUTES } = FIELDS;

    return {
      [DEPARTURES.ID]: '',
      [ROUTES.ID]: '',
    };
  }


  /**
   * Constructor
   */
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);

    this.state = {
      values: this.constructor.values(),
      showInitial: true,
      listLength: SHOW_INITIAL_LENGTH,
      showMoreText: 'Show all dates',
    };
  }


  /**
   * After a component is mounted
   */
  componentDidMount() {
    if (isMobileScreen(window)) {
      this.setState({ showMoreText: 'Show more dates' });
    } else {
      this.setState({ showMoreText: 'Show all dates' });
    }
  }


  /**
   * On change event
   */
  onChange(value) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        ...value,
      },
    }));
  }

  /**
   * Filter the list of dates
   */
  filterList() {
    const { list } = this.props;
    const { values } = this.state;
    const { DEPARTURES, ROUTES, SINGLE_ROUTES } = FIELDS;

    let filteredList = list;

    if (values[DEPARTURES.ID]) {
      switch (values[DEPARTURES.ID]) {
        case 'next-30-days':
          filteredList = filteredList.filter(departure => daysFromNow(departure.date) <= 30);
          break;
        case 'next-90-days':
          filteredList = filteredList.filter(departure => daysFromNow(departure.date) <= 90);
          break;
        case 'after-90-days':
          filteredList = filteredList.filter(departure => daysFromNow(departure.date) > 90);
          break;
        default:
      }
    }

    if (values[ROUTES.ID]) {
      switch (values[ROUTES.ID]) {
        case ROUTE_OPTIONS.STANDARD.value: {
          filteredList = filteredList.filter(departure => !departure.reverse);
          break;
        }
        case ROUTE_OPTIONS.REVERSE.value: {
          filteredList = filteredList.filter(departure => departure.reverse);
          break;
        }
        default: {
          filteredList = filteredList.filter(departure => !departure.reverse);
        }
      }
    }

    if (values[SINGLE_ROUTES.ID]) {
      switch (values[SINGLE_ROUTES.ID]) {
        case ROUTE_OPTIONS.STANDARD.value: {
          filteredList = filteredList.filter(departure => !departure.reverse);
          break;
        }
        case ROUTE_OPTIONS.REVERSE.value: {
          filteredList = filteredList.filter(departure => departure.reverse);
          break;
        }
        default: {
          filteredList = filteredList.filter(departure => !departure.reverse);
        }
      }
    }

    for (let i = 0; i < filteredList.length; i += 1) {
      const daysBeforeDeparture = (moment(filteredList[i].date).diff(moment(), 'days') + 1);
      const tourType = filteredList[i].tour_type;
      let link = `${PAGES.BOOKINGS.PATH}?departureId=${filteredList[i].id}`;
      let buttonText = 'Book';

      const isOnRequest = (daysBeforeDeparture <= 30 && tourType !== 'Snow') || (daysBeforeDeparture <= 14 && tourType === 'Snow');
      if (daysBeforeDeparture <= 7) {
        buttonText = 'Book Now';
      } else if (isOnRequest) {
        link = PAGES.LATE_REQUEST.PATH;
        buttonText = 'On Request';
      }

      filteredList[i].daysBeforeDeparture = daysBeforeDeparture;
      filteredList[i].link = link;
      filteredList[i].buttonText = buttonText;
    }

    return filteredList;
  }


  /**
   * Handler the show more list
   */
  handlerShowMore() {
    const { listLength } = this.state;

    if (isMobileScreen(window)) {
      this.setState({ listLength: listLength + 10 });
    } else {
      this.setState({ listLength: this.filterList().length });
    }
  }

  render() {
    const {
      list,
      tourName,
      tourSlug,
      tourStartLocation,
      tourEndLocation,
      hasReverse,
    } = this.props;

    const {
      values,
      showInitial,
      listLength,
      showMoreText,
    } = this.state;

    const {
      DEPARTURES,
      ROUTES,
      SINGLE_ROUTES,
    } = FIELDS;

    const filteredList = this.filterList().slice(0, listLength);

    if (filteredList.length === this.filterList().length) {
      this.setState({ showInitial: false });
    } else {
      this.setState({ showInitial: true });
    }

    return (
      <Element
        name={TOUR_PAGE_NAV.DATES.NAME}
        className="l-tour-dates l-tour-page__section"
      >
        <h2 className="c-heading c-heading--h2">Available dates</h2>
        <p>
          {
            `Only $${NEW_ADVENTURE_TOUR_SLUGS.includes(tourSlug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT} to secure your seat unless you book within ${PARTIAL_PAYMENT_DEADLINE} days of departure.
          Once your spot is booked, you can log in and customise your tour with any extra experiences or activities up to
          ${PARTIAL_PAYMENT_DEADLINE} days before departure.`
          }
        </p>

        <div className="l-tour-dates__filters">
          <div className="l-tour-dates__filters-grid">
            <Select
              label="Departures"
              placeholder="All departures"
              id={DEPARTURES.ID}
              name={DEPARTURES.ID}
              options={FIELDS.DEPARTURES.OPTIONS}
              value={values[DEPARTURES.ID]}
              onChange={this.onChange}
              srOnly
            />
            {hasReverse ? (
              <Select
                label="Routes"
                placeholder="All routes"
                id={ROUTES.ID}
                name={ROUTES.ID}
                options={FIELDS.ROUTES.OPTIONS}
                value={values[ROUTES.ID]}
                onChange={this.onChange}
                srOnly
              />
            ) : (
              <Select
                label="Routes"
                placeholder="All routes"
                id={SINGLE_ROUTES.ID}
                name={SINGLE_ROUTES.ID}
                options={FIELDS.SINGLE_ROUTES.OPTIONS}
                value={values[SINGLE_ROUTES.ID]}
                onChange={this.onChange}
                srOnly
              />
            )}
          </div>
        </div>

        <div className="c-date-list">
          {list.length === 0 && (
            <h4 className="c-date-list__loading c-heading c-heading--h4">Loading...</h4>
          )}

          {list.length > 0 && filteredList.length === 0 && (
            <h4 className="c-date-list__loading c-heading c-heading--h4">No results found</h4>
          )}

          {filteredList.map(item => (
            <div
              key={item.id}
              className={`c-date-list__item ${item.available_seats === 0 ? 'c-date-list__item--sold-out' : ''}`}
            >
              <div className="c-date-list__item-first">
                <div className="c-date-list__item-date c-date-list__item-date--departure">
                  {item.reverse ? (
                    <React.Fragment>
                      <ReactTooltip
                        id={`${item.id}_reverse`}
                        type="dark"
                        effect="solid"
                        className="c-tooltip"
                        multiline
                        border
                      >
                        <span>This is a reverse tour.</span>
                      </ReactTooltip>
                    </React.Fragment>
                  ) : ''}
                  <span className="c-date-list__item-date-day">{moment(item.date).format('dddd')}</span>
                  <span className="c-date-list__item-date-date">{moment(item.date).format('D MMM, YYYY')}</span>
                  <span className="c-date-list__item-date-location">from <strong>{item.reverse ? tourEndLocation : tourStartLocation}</strong></span>
                </div>
                <div className="c-date-list__item-divider">
                  {(item.reverse && tourName === 'Southern Wonders') ? (
                    <React.Fragment>
                      <ReactTooltip
                        id={`${item.id}_reverse`}
                        type="dark"
                        effect="solid"
                        className="c-tooltip"
                        multiline
                        border
                      >
                        <span>This is a reverse tour.</span>
                      </ReactTooltip>
                      <span
                        className="c-date-list__item-divider-reverse-tag"
                        data-for={`${item.id}_reverse`}
                        data-tip
                      >
                        reverse
                      </span>
                    </React.Fragment>
                  ) : ''}
                  <div className="c-date-list__item-divider-line" />
                </div>
                <div className="c-date-list__item-date c-date-list__item-date--arrival">
                  <span className="c-date-list__item-date-day">{moment(item.end_date).format('dddd')}</span>
                  <span className="c-date-list__item-date-date">{moment(item.end_date).format('D MMM, YYYY')}</span>
                  <span className="c-date-list__item-date-location">to <strong>{item.reverse ? tourStartLocation : tourEndLocation}</strong></span>
                </div>
              </div>

              <div className="c-date-list__item-second">
                <div className="c-date-list__item-price">
                  <span className="c-date-list__item-price-total">
                    {numberToCurrency(item.tour_price)}
                  </span>
                  <span className="c-date-list__item-price-reserve">${NEW_ADVENTURE_TOUR_SLUGS.includes(item.tour_slug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT} to reserve your seat</span>
                </div>

                {item.available_seats < 1 && (
                  <div className="c-date-list__item-date c-date-list__item-date--seats">
                    <span className="c-date-list__item-date-day">Sorry</span>
                    <span className="c-date-list__item-date-date">Sold out</span>
                    <span className="c-date-list__item-date-location">No seats available</span>
                  </div>
                )}

                {item.available_seats > 0 && item.available_seats < 8 && (
                  <>
                    <div className="c-date-list__item-date c-date-list__item-date--seats">
                      <span className="c-date-list__item-date-day">Only</span>
                      <span className="c-date-list__item-date-date">
                        {item.available_seats}
                      </span>
                      <span className="c-date-list__item-date-location">Seats left</span>
                    </div>

                    <div className="c-date-list__item-button">
                      { item.daysBeforeDeparture <= 7 ? (
                        <React.Fragment>
                          <ReactTooltip
                            id={item.id}
                            type="dark"
                            effect="solid"
                            className="c-tooltip"
                            multiline
                            border
                          >
                            <span>This departure has now closed for booking. <a href="/contact-us" target="_blank">Contact us</a> for last minute bookings.</span>
                          </ReactTooltip>
                          <button
                            type="button"
                            className="c-button c-button--border c-button--block c-button-inactive"
                            data-tip
                            data-for={item.id}
                          >
                            {item.buttonText}
                          </button>
                        </React.Fragment>
                      ) : (
                        <Link
                          to={item.link}
                          className="c-button c-button--border c-button--block"
                        >
                          {item.buttonText}
                        </Link>
                      )}
                      {(item.special_deal) && (
                        <div className="c-date-list__last_minute">&#10003; Limited time discount</div>
                      )}
                      {(item.upgrade_accommodation_unavailable) && (
                        <div className="c-date-list__last_minute">&#10003; Dorm-Share Only</div>
                      )}
                      {item.guaranteed_depart && (
                        <div className="c-date-list__guaranteed">&#10003; Departure Guaranteed</div>
                      )}
                    </div>
                  </>
                )}

                {item.available_seats > 7 && (
                  <>
                    <div className="c-date-list__item-date c-date-list__item-date--seats">
                      <span className="c-date-list__item-date-day">&nbsp;</span>
                      <span className="c-date-list__item-date-date">
                        Seats available
                      </span>
                      <span className="c-date-list__item-date-location">&nbsp;</span>
                    </div>

                    <div className="c-date-list__item-button">
                      { item.daysBeforeDeparture <= 7 ? (
                        <React.Fragment>
                          <ReactTooltip
                            id={item.id}
                            type="dark"
                            effect="solid"
                            className="c-tooltip"
                            multiline
                            border
                          >
                            <span>This departure has now closed for booking. <a href="/contact-us" target="_blank">Contact us</a> for last minute bookings.</span>
                          </ReactTooltip>
                          <button
                            type="button"
                            className="c-button c-button--border c-button--block c-button-inactive"
                            data-tip
                            data-for={item.id}
                          >
                            {item.buttonText}
                          </button>
                        </React.Fragment>
                      ) : (
                        <Link
                          to={item.link}
                          className="c-button c-button--border c-button--block"
                        >
                          {item.buttonText}
                        </Link>
                      )}
                      {(item.special_deal) && (
                        <div className="c-date-list__last_minute">&#10003; Limited time discount</div>
                      )}
                      {(item.upgrade_accommodation_unavailable) && (
                        <div className="c-date-list__last_minute">&#10003; Dorm-Share Only</div>
                      )}
                      {item.guaranteed_depart && (
                        <div className="c-date-list__guaranteed">&#10003; Departure Guaranteed</div>
                      )}
                    </div>
                  </>
                )}

              </div>
              <div className="c-date-list__item-third">
                {(item.special_deal) && (
                  <div className="c-date-list__item-third__last_minute">&#10003; Limited time discount</div>
                )}
                {(item.upgrade_accommodation_unavailable) && (
                  <div className="c-date-list__last_minute">&#10003; Dorm-Share Only</div>
                )}
                {item.guaranteed_depart && (
                  <div className="c-date-list__item-third__guaranteed">&#10003; Departure Guaranteed</div>
                )}
              </div>
            </div>
          ))}
        </div>

        {filteredList.length > 0 ? (
          <div className={`l-tour-dates__show-all ${showInitial ? '' : 'is-active'}`}>
            {showInitial
              ? (
                <button
                  type="button"
                  className="c-button c-button--primary c-button--wide"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlerShowMore();
                  }}
                >
                  { showMoreText }
                </button>
              ) : (
                <button
                  type="button"
                  className="c-button c-button--primary c-button--wide"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ listLength: SHOW_INITIAL_LENGTH });
                    if (!showInitial) {
                      scroller.scrollTo(TOUR_PAGE_NAV.DATES.NAME, {
                        ...SCROLL_CONFIG,
                        offset: isMobileScreen(window) ? -HEADER_HEIGHT_MOBILE : -(HEADER_HEIGHT + TOUR_PAGE_NAV_HEIGHT + 20),
                      });
                    }
                  }}
                >
                  Hide dates
                </button>
              )
            }
          </div>
        ) : null}
      </Element>
    );
  }
}

Dates.propTypes = {
  tourName: PropTypes.string.isRequired,
  tourSlug: PropTypes.string.isRequired,
  tourStartLocation: PropTypes.string.isRequired,
  tourEndLocation: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  hasReverse: PropTypes.any.isRequired,
};

export default Dates;
