import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Element } from 'react-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { STATES, TOUR_PAGE_NAV } from 'config';
import { getImageUrl } from 'utilities';
import SVGAccordionPlus from '../../../../../public/svg/accordion-plus.svg';


class Itinerary extends PureComponent {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    this.showOriginal = this.showOriginal.bind(this);
    this.showReverse = this.showReverse.bind(this);
    this.toggleFullList = this.toggleFullList.bind(this);

    this.state = {
      showReverse: false,
      showFullList: false,
    };
  }


  /**
   * Show the original route
   */
  showOriginal() {
    this.setState({
      showReverse: false,
    });
  }


  /**
   * Show the reverse route
   */
  showReverse() {
    this.setState({
      showReverse: true,
    });
  }


  /**
   * Toggle the full list of itinerary days
   */
  toggleFullList() {
    this.setState(prevState => ({
      showFullList: !prevState.showFullList,
    }));
  }


  /**
   * Render
   */
  render() {
    const { pageContext, images } = this.props;
    const { showReverse, showFullList } = this.state;

    const itineraryList = showReverse ? pageContext.reverse_days : pageContext.days;

    return (
      <Element
        name={TOUR_PAGE_NAV.ITINERARY.NAME}
        className="l-tour-itinerary l-tour-page__section"
      >
        <h3 className="c-heading c-heading--h3">Tour itinerary</h3>

        {pageContext.has_reverse && (
          <div className="l-tour-itinerary__btn-container">
            <button
              type="button"
              className="c-button c-button--black c-button--small"
              onClick={this.showOriginal}
              style={{ opacity: showReverse ? '0.4' : '1' }}
            >
              {`${pageContext.start_location} to ${pageContext.end_location}`}
            </button>

            <button
              type="button"
              className="c-button c-button--black c-button--small"
              onClick={this.showReverse}
              style={{ opacity: showReverse ? '1' : '0.4' }}
            >
              {`${pageContext.end_location} to ${pageContext.start_location}`}
            </button>
          </div>
        )}

        <div className="l-tour-itinerary__image">
          <LazyLoadImage
            src={getImageUrl({
              id: pageContext.main_picture_id,
              images,
              height: 391,
            })}
            wrapperClassName="lazy-img-wrapper"
            effect="blur"
            alt="Tour itinerary"
          />
        </div>

        <div className={`l-tour-itinerary__list ${showFullList ? STATES.ACTIVE : ''}`}>
          <div className="c-itinerary-list">
            {itineraryList.map(day => (
              <li
                key={day.api_id}
                className="c-itinerary-list__item"
                data-ordinal-number={day.ordinal_number}
              >
                <Link
                  to={day.absolute_slug}
                  className="c-itinerary-list__link"
                >
                  <div className="c-itinerary-list__link-image">
                    <LazyLoadImage
                      src={`${day.image_url}?w=160&h=110&fit=crop&auto=format`}
                      effect="blur"
                      alt={day.name}
                    />
                  </div>
                  <div className="c-itinerary-list__link-text">
                    <h6 className="c-heading c-heading--h6">
                      {day.location_start}
                      {day.location_end ? ` to ${day.location_end}` : null}
                    </h6>
                    <p>
                      {day.short_description_markdown ? day.short_description_markdown.substring(0, 200) : null}
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </div>

          <div className="l-tour-itinerary__list-pagination">
            <button
              type="button"
              className={`c-button c-button--primary c-button--wide ${showFullList ? 'is-active' : ''}`}
              onClick={this.toggleFullList}
            >
              {`${showFullList ? 'Hide' : 'Show'} full itinerary`}
              <SVGAccordionPlus />
            </button>
          </div>
        </div>
      </Element>
    );
  }
}

Itinerary.propTypes = {
  pageContext: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
};

export default Itinerary;
