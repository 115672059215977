import React from 'react';
// import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { TourReviewRating } from 'components';

const TourBanner = ({ title, tourId }) => (
  <section className="l-tour-banner">
    <div className="l-container">
      <div className="l-tour-banner__inner">
        <h2 className="c-heading c-heading--h1">
          {title}
        </h2>
        <div className="star-rating">
          <TourReviewRating tour={tourId} showTotal /> &nbsp;
        </div>
      </div>
    </div>
  </section>
);

TourBanner.propTypes = {
  title: PropTypes.string.isRequired,
  tourId: PropTypes.string.isRequired,
};

export default TourBanner;
