import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import { TOUR_PAGE_NAV } from 'config';

const showdown = require('showdown');

const converter = new showdown.Converter();

const WhatsIncluded = ({ markdown }) => {
  if (!markdown) return null;

  return (
    <Element
      name={TOUR_PAGE_NAV.INCLUDED.NAME}
      className="l-tour-included l-tour-page__section"
    >
      <h3 className="c-heading c-heading--h3">What’s included?</h3>
      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(markdown) }} />
    </Element>
  );
};

WhatsIncluded.propTypes = {
  markdown: PropTypes.string,
};

WhatsIncluded.defaultProps = {
  markdown: null,
};

export default WhatsIncluded;
