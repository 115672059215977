import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

import {
  STATES,
  HEADER_HEIGHT,
  TOUR_PAGE_NAV,
  TOUR_PAGE_NAV_HEIGHT,
} from 'config';


const PageNav = ({ exclude, hasMessage }) => (
  <nav className={`c-tour-nav ${hasMessage ? 'c-tour-nav--message' : ''}`}>
    <div className="l-container">
      <ul className="c-tour-nav__list">
        {Object.values(TOUR_PAGE_NAV).filter(item => !exclude.includes(item.NAME)).map(item => (
          <li
            key={item.NAME}
            className="c-tour-nav__item"
          >
            <Link
              to={item.NAME}
              className="c-tour-nav__link"
              activeClass={STATES.ACTIVE}
              offset={-(HEADER_HEIGHT + TOUR_PAGE_NAV_HEIGHT + 20)}
              duration={800}
              smooth
              spy
            >
              {item.TITLE}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </nav>
);

PageNav.propTypes = {
  exclude: PropTypes.array,
  hasMessage: PropTypes.bool,
};

PageNav.defaultProps = {
  exclude: [],
  hasMessage: false,
};

export default PageNav;
