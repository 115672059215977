import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { scroller } from 'react-scroll';
import { CalendarModal } from 'components';
import {
  PAGES,
  STATES,
  HEADER_HEIGHT,
  SCROLL_CONFIG,
  TOUR_PAGE_NAV,
  TOUR_PAGE_NAV_HEIGHT, HEADER_HEIGHT_MOBILE,
} from 'config';
import {
  numberToCurrency, isMobileScreen, getRate, calculatePriceWithRate,
} from 'utilities';
import { PulseLoader } from 'react-spinners';
import SVGPlus from '../../../../../public/svg/plus.svg';
import SVGCalendar from '../../../../../public/svg/calendar.svg';

const showdown = require('showdown');

const converter = new showdown.Converter();


class SideBox extends PureComponent {
  constructor() {
    super();

    this.scrollEvent = this.scrollEvent.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);
    this.handleOpenCalendar = this.handleOpenCalendar.bind(this);
    this.handleCloseCalendar = this.handleCloseCalendar.bind(this);

    this.state = {
      showTitle: false,
      showFixed: false,
      showMoreButtons: false,
      openCalendar: false,
      rate: null,
    };
  }

  async componentDidMount() {
    try {
      const rate = await getRate();
      this.setState({ rate });
    } catch (error) {
      console.error('Error fetching rate:', error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollEvent);
  }


  onMoreClick() {
    this.setState(prevState => ({
      showMoreButtons: !prevState.showMoreButtons,
    }));
  }

  handleOpenCalendar() {
    this.setState({
      openCalendar: true,
    });
  }

  handleCloseCalendar() {
    this.setState({
      openCalendar: false,
    });
  }


  scrollEvent() {
    let showTitle = false;
    let showFixed = false;

    const { promoBanner, hasMessage } = this.props;

    let breakPoint = isMobileScreen(window) ? 75 : 120;

    if (hasMessage) {
      breakPoint = isMobileScreen(window) ? 75 : 178;
    }

    if (window.pageYOffset > breakPoint) {
      showTitle = true;
    }

    if (promoBanner.length === 0) {
      if (window.pageYOffset > 780) {
        showFixed = true;
      }
    }
    if (promoBanner.length > 0) {
      if (window.pageYOffset > 680) {
        showFixed = true;
      }
    }

    this.setState({ showTitle, showFixed });
  }


  render() {
    const {
      showTitle,
      showMoreButtons,
      showFixed,
      openCalendar,
      rate,
    } = this.state;

    const {
      tourId,
      title,
      duration,
      price,
      deposit,
      showDownload,
      modifier,
      promoBanner,
      hasMessage,
      isBornToRoam,
      inactiveDays,
      tourSlug,
    } = this.props;

    return (
      <section className={`c-side-box ${modifier} ${!showFixed ? STATES.COLLAPSED : ''} ${showMoreButtons ? STATES.EXPANDED : ''} ${hasMessage ? 'c-side-box--message' : ''}`}>
        <div className="first-box">
          <h3 className={`c-heading c-heading--h3 ${!showTitle ? 'u-display--none' : ''}`}>
            {title}
          </h3>

          <dl>
            <div>
              <dt>Days</dt>
              <dd>
                {duration}
              </dd>
            </div>

            <div>
              <dt>Price from (NZD)</dt>
              <dd>
                {rate !== null ? (
                  numberToCurrency(calculatePriceWithRate(price, rate))
                ) : (
                  <PulseLoader
                    sizeUnit="px"
                    size={8}
                    color="#000"
                  />
                )}
                {console.log("Price: ", price)}
                {console.log("Rate: ", rate)}
                {console.log("Calculated: ", calculatePriceWithRate(price, rate))}
              </dd>
            </div>

            <div>
              <dt>Deposit only</dt>
              <dd>
                {numberToCurrency(deposit)}
              </dd>
            </div>
          </dl>

          <div className="c-button-container c-button-container--block">
            {!isBornToRoam && (
              <button
                type="button"
                className="c-button c-button--primary c-button--block c-side-box__book-btn"
                onClick={() => scroller.scrollTo(TOUR_PAGE_NAV.DATES.NAME, {
                  ...SCROLL_CONFIG,
                  offset: isMobileScreen(window) ? -HEADER_HEIGHT_MOBILE : -(HEADER_HEIGHT + TOUR_PAGE_NAV_HEIGHT + 20),
                })}
              >
                See available dates + book
                <SVGCalendar />
              </button>
            )}
            {isBornToRoam && (
              <button
                type="button"
                className="c-button c-button--primary c-button--block c-side-box__book-btn"
                onClick={this.handleOpenCalendar}
              >
                See available dates + book
                <SVGCalendar />
              </button>
            )}
            <button
              type="button"
              className="c-button c-button--border c-side-box__more-btn"
              onClick={this.onMoreClick}
            >
              {showMoreButtons ? 'Less' : 'More'}
              <SVGPlus />
            </button>
          </div>

          <CalendarModal
            open={openCalendar}
            tourId={tourId}
            tourSlug={tourSlug}
            inactiveDays={inactiveDays}
            handleClose={() => {
              this.handleCloseCalendar();
            }}
          />

          <div className="c-button-container c-button-container--block">
            <Link
              to={PAGES.CONTACT.PATH}
              className="c-button c-button--border-alt c-button--block"
            >
              {PAGES.CONTACT.TITLE}
            </Link>

            <button
              type="button"
              className="c-button c-button--border-alt c-button--block"
              onClick={(e) => {
                e.preventDefault();
                // This interacts a global JS chat widget: https://www.jivochat.com/api/
                // eslint-disable-next-line
                if (jivo_api && typeof jivo_api.open === 'function') {
                  jivo_api.open(); // eslint-disable-line
                }
              }}
            >
              Live chat
            </button>
          </div>

          {showDownload && (
            <Link
              to={`${PAGES.ITINERARY_DOWNLOAD.PATH}?tour_id=${tourId}`}
              className="c-button c-button--border-alt c-button--block"
            >
              Download itinerary
            </Link>
          )}
        </div>
        {promoBanner.length > 0 && (
          <a href={promoBanner[0].link}>
            <div className="second-box" style={{ backgroundColor: promoBanner[0].title === 'Lights! Camera! Action!' ? '#129A63' : null }}>
              <h3 className="c-heading c-heading--h3">
                {promoBanner[0].title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(promoBanner[0].description_markdown),
                }}
              />
            </div>
          </a>
        )}
      </section>
    );
  }
}

SideBox.propTypes = {
  tourId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDownload: PropTypes.bool.isRequired,
  modifier: PropTypes.string,
  tourSlug: PropTypes.string,
  promoBanner: PropTypes.any,
  hasMessage: PropTypes.bool,
  isBornToRoam: PropTypes.bool,
  inactiveDays: PropTypes.any,
};

SideBox.defaultProps = {
  duration: '',
  price: '',
  deposit: '',
  modifier: '',
  tourSlug: '',
  promoBanner: [],
  inactiveDays: [],
  hasMessage: false,
  isBornToRoam: false,
};

export default SideBox;
